/** @format */

import React from "react";
import { RiArrowRightUpLine } from "react-icons/ri";
import ConnectWallet from "../../components/elements/tokenData/components/wallet/ConnectWallet";
import Notiflix from "notiflix";
import { useWeb3React } from "@web3-react/core";
const Header = () => {
  const { active, account, error, chainId } = useWeb3React();
  const longSting = str => {
    return str.length > 41
      ? `${str.substring(0, 4)}  ... ${str.substring(38, 42)}`
      : str;
  };
  Notiflix.Notify.init({
    width: "300px",
    position: "center-bottom",
    closeButton: false,
    distance: "80px",
  });
  Notiflix.Loading.init({
    backgroundColor: "rgba(0,0,0,0.8)",
    svgColor: "#fff",
    clickToClose: false,
  });
  return (
    <div className='Header-Section '>
      <nav className='navbar navbar-expand-lg navbar-light'>
        <div className='container-fluid'>
          <a className='navbar-brand' href='/'>
            {" "}
            <img
              src='assets/oscarswap.png'
              width={180}
              className='img-fluid'
              alt=''
            />
          </a>
          <button
            className='navbar-toggler'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#navbarSupportedContent'
            aria-controls='navbarSupportedContent'
            aria-expanded='false'
            aria-label='Toggle navigation'>
            <span className='navbar-toggler-icon' />
          </button>
          <div className='collapse navbar-collapse' id='navbarSupportedContent'>
            <div className='me-auto'></div>
            <div className='me-auto'></div>
            <ul className='navbar-nav mb-2 mb-lg-0'>
              <li className='nav-item'>
                <a className='nav-link active' aria-current='page' href='/'>
                  ARB Airdrop
                </a>
              </li>
              <li className='nav-item'>
                <a className='nav-link' href='/'>
                  Stake
                </a>
              </li>
              <li className='nav-item'>
                <a className='nav-link' href='/'>
                  Vaults
                </a>
              </li>
              <li className='nav-item'>
                <a className='nav-link' href='/'>
                  Convert
                </a>
              </li>
              <li className='nav-item'>
                <a className='nav-link' href='/'>
                  <span>Stats</span> <RiArrowRightUpLine className='fs-6' />
                </a>
              </li>
            </ul>

            {active ? (
              <button className='navbar-item'>
                {chainId === 42161
                  ? longSting(account ? account : null)
                  : "Wrong network"}
              </button>
            ) : (
              <ConnectWallet classN='navbar-item' />
            )}
            {/* <div className="navbar-item">
              <a href="#!">CONNECT WALLET</a>
            </div> */}
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
