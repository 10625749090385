/** @format */

import React from "react";
import { Button } from "react-bootstrap";

import { useWeb3React } from "@web3-react/core";
import { injected } from "./connectors";




export const ConnectWallet = ({ classN }) => {
  const { activate } = useWeb3React();

  async function connect() { 
    try {
      await activate(injected);
    } catch (err) {
      console.log(err);
    }
  }

 


  return (
    <>
      <button className={classN} onClick={connect}>
        Connect wallet
      </button>
 
    </>
  );
};

export default ConnectWallet;
