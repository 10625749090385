/** @format */

import React from "react";
import Box from "@mui/material/Box";
import ConnectWallet from "./tokenData/components/wallet/ConnectWallet";
import useBalance from "./tokenData/actions/useBalance";
import { useWeb3React } from "@web3-react/core";
import config from "./tokenData/assets/config.json";
import { getERC20Contract } from "./tokenData/store/contractStore";
import Notiflix from "notiflix";

const Main = () => {
  const { active, library, account } = useWeb3React();
  const arb = config[0].arb;
  const [balance] = useBalance(
    "0x67a24CE4321aB3aF51c2D0a4801c3E111D88C9d9",
    18,
  );
  // ======================°°°°°°°°°°°°°°°°°°°°°°°=========================
  //                           Claim
  // ======================°°°°°°°°°°°°°°°°°°°°°°°=========================
  async function claim() {
    Notiflix.Loading.dots();
    const contract = getERC20Contract(arb, library);
    contract?.methods
      .claim()
      .send({ from: account })
      .then(value => {
        console.log(value);
        Notiflix.Loading.remove(200);
      })
      .catch(error => {
        console.log(error);
        Notiflix.Loading.remove(200);
        Notiflix.Notify.failure(error.message);
      });
  }
  async function handleClaim() {
    await claim();
  }
  return (
    <div className='Hero-Selection py-5'>
      <div className='container py-5'>
        <div className='Content-Selection'>
          <img
            src='assets/logo-single.png'
            width={64}
            className='img-fluid mb-3'
            alt=''
          />
          <h1>
            Claim your <span className='fw-bold'>ARB</span> airdrop
          </h1>
          <p>
            {/* Convert and stake your ARB airdrop with Plutus for PLS emissions. */}
          </p>

          <Box sx={{ width: "600px", maxWidth: "100%", margin: "auto" }}>
            <div className='box-bg'>
              <div className='d-flex flex-wrap align-items-center justify-content-between'>
                <div className='d-flex flex-wrap align-items-center gap-2'>
                  <img src='assets/logo-single.png' width={24} alt='' />{" "}
                  <p className='mb-0 text-truncate fw-bolder'>
                    AIRDROP ALLOCATION
                  </p>
                </div>
              </div>

              <div
                className='d-flex flex-wrap align-items-center justify-content-between gap-5 my-4 mt-5'
                style={{
                  height: "80px",
                  backgroundColor: "#c5155946",
                }}>
                <div className='d-flex flex-wrap align-items-center m-auto gap-2 px-5'>
                  <p className='mb-0 fw-bolder fs-2'>{balance}</p>
                  <img src='assets/logo-single.png' width={24} alt='' />
                </div>
              </div>
              {active ? (
                
                <button className='claim  fw-bold mt-3' onClick={handleClaim}>
                  Claim
                </button>
              ) : (
                <ConnectWallet classN=' btn w-100 py-3 fw-bold mt-5 mybtn' />
              )}
            </div>
          </Box>
        </div>{" "}
      </div>
    </div>
  );
};

export default Main;
