/** @format */

import React from "react";

const Footer = () => {
  return (
    <div className='Footer-Section fixed-bottom py-3 mt-5'>
      <div className='container'>
        <div className='content-section d-flex align-items-center justify-content-between'>
          <a href='/'>
            <img
              src='assets/oscarswap.png'
              width={180}
              className='img-fluid'
              alt=''
            />
          </a>

          <ul className='p-0 d-flex gap-4'>
            <li>
              <a
                href='https://twitter.com/Oscar_Swap'
                rel='noreferrer'
                target='_blank'>
                Twitter
              </a>
            </li>
            <li>
              <a
                href='http://discord.gg/rdJsQttk'
                rel='noreferrer'
                target='_blank'>
                Discord
              </a>
            </li>
            <li>
              <a href='http://t.me/Oscar_Swap' rel='noreferrer' target='_blank'>
                Telegram
              </a>{" "}
            </li>
            <li>
              <a
                href='https://github.com/Oscarswap'
                rel='noreferrer'
                target='_blank'>
                GitHub
              </a>{" "}
            </li>
            <li>
              <a
                href='https://www.instagram.com/oscar_swap/'
                rel='noreferrer'
                target='_blank'>
                Instagram
              </a>{" "}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Footer;
