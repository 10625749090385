/** @format */
/** @format */

import { InjectedConnector } from "@web3-react/injected-connector";

export const injected = new InjectedConnector({
  supportedChainIds: [
    42161,421613, 56, 97, 43114, 1, 137, 25, 250, 42220, 1285, 1284, 3, 4, 31, 42, 63, 62,
    2221, 43113, 62320, 2222, 128, 256, 80001, 44787, 62320, 1287, 4002, 5,
    1337,
  ],
});
