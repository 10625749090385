/** @format */

import React from "react";
import Hero from "../components/elements/Hero";
import Main from "../components/elements/Main";

const Home = () => {
  return (
    <>
      <Main />
    </>
  );
};

export default Home;
