/** @format */

import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "notiflix/dist/notiflix-3.2.6.min.css";
import "notiflix/dist/notiflix-3.2.6.min.js";
import RoutesPage from "./pages/routesPage/RoutesPage.jsx";
import { Web3ReactProvider } from "@web3-react/core";
import Web3 from "web3";
import ReactGA from "react-ga";
ReactGA.initialize("UA-261507667-1");
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  function getLibrary(provider) {
    return new Web3(provider);
  }
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <RoutesPage />
    </Web3ReactProvider>
  );
}

export default App;
